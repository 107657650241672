.content {
    margin: 16px 0 40px 0;
}

.container {
    padding: 0 32px;
    overflow: auto;
}

.button {
    margin-left: 12px !important;
}

.row {
    width: 100%;
    text-align: left !important;
    box-sizing: border-box;
}

.col {
    margin-top: 12px;
}

.row > * {
    pointer-events: none;
}

.row:not(:last-child) {
    border-bottom: 1px solid var(--color-light-border-primary);
    border-radius: 0;
}

.empty_location {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 0;
}

.head {
    padding: 0 0 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
}
